<template>
  <div>
    <div v-if="isClose" class="poiTaskDistribution">
      <h3>{{ poiTaskName }}</h3>
      <div>{{ jobEndTime }}</div>
      <div class="poiTaskDistributionMain">
        <div class="information">省市信息：{{ provCity }}</div>
        <div class="information copy">
          <span id="address">地址信息：{{ address || "暂无" }}</span>
          <van-button
            v-if="!!address"
            size="mini"
            class="copyBtn"
            @click="handleCopy"
            >复制</van-button
          >
        </div>
        <div class="information">地址搜索结果截图</div>
      </div>
      <div class="submit">
        <van-button
          class="submitBtn"
          :loading="loading"
          loading-text="提交中..."
          @click="handleSubmit"
        >
          点我提交!
        </van-button>
        <div class="feedback">如无法完成任务，请<span class="linkText" @click="clickFeedback">点击此处</span>反馈</div>
      </div>
    </div>
    <van-empty v-else description />
  </div>
</template>

<script>
import { Dialog, Toast } from "vant";
import { ACCEPT_IMAGE, ACCEPT_VIDEO } from "@/constant";
import { taskSubmit, getTaskDetails, judgeWhetherUploadVideoPoi } from "@/apis";
import { formatDate } from "@/tools/date";
import {
  checkUploadImages,
  copyText,
  getUrlParams,
  userAccessValid,
} from "../shared";
// import { updateUniqueFileList } from "@/tools/file-upload";

export default {
  name: "SkuTask",
  components: {},
  data() {
    return {
      loading: false,
      urlParams: {},
      accept: ACCEPT_IMAGE.join(","),
      poiTaskName: "",
      jobEndTime: "",
      provCity: "",
      address: "",
      companyId: null,
      jobId: null,
      isClose: true,
      taskStartTime: 0,
      taskEndTime: 0,
    };
  },
  created() {
    this.urlParams = getUrlParams(this.$route.query);
    this.checkUploadVideo();
    this.getUserAccessValid();
  },
  methods: {
    async checkUploadVideo() {
      if (window.eraState.elk) {
        this.accept = "";
        return;
      }
      // 接口校验该用户是否可以上传视频，data为true则可以上传视频
      const res = await judgeWhetherUploadVideoPoi();
      if (res && res.code === 0 && res.data) {
        this.accept = [...ACCEPT_IMAGE, ...ACCEPT_VIDEO].join(",");
      }
    },
    // h5鉴权接口
    async getUserAccessValid() {
      const isValid = await userAccessValid(this.urlParams);
      this.isClose = isValid;
      if (isValid) {
        await this.handlePoiDetails();
      }
    },
    async handlePoiDetails() {
      let res = await getTaskDetails({ userTaskId: this.urlParams.userTaskId,status: this.urlParams.status ? +this.urlParams.status : undefined });
      try {
        if (res) {
          if (res.code !== 0) return Toast.fail(res.msg);
          const data = res.data || {};
          const time = formatDate(
            new Date(data.jobEndTime),
            "yyyy-MM-dd HH:mm:ss"
          );
          const details = JSON.parse(data.detail || "{}");
          this.poiTaskName = data.name;
          this.jobEndTime = `有效期至：${time}`;
          this.provCity = data.provinceName
            ? `${data.provinceName}-${data.cityName}`
            : `${data.cityName}`;
          this.address = data.address;
          this.jobId = data.jobId;
          if (details) {
            this.urlParams.cityId = details.cityId;
            this.companyId = details.companyId;
          }
        }
      } catch (err) {
        Toast.fail(err.message);
      }
    },
    // 提交任务接口
    async fetchTaskNew(submitInfo) {
      let params = {
        jobId: this.urlParams.jobId || this.jobId,
        cityId: this.urlParams.cityId,
        userTaskId: this.urlParams.userTaskId,
        dataType: 4, // 1.sku 采集 2.poi采集 4.开村探测
        companyId: this.companyId,
        ...submitInfo,
      };
      try {
        let res = await taskSubmit(params);
        this.taskEndTime = Date.now();
        if (res) {
          this.loading = false;
          let msg = "恭喜您提交任务成功！";
          let confirmButtonText = "多喝热水！";
          if (res.code !== 0) {
            msg = res.msg || "很遗憾任务提交失败！";
            confirmButtonText = "我再试试！";
          }
          if (res.code === 0) {
            // updateUniqueFileList(imgs);
          }
          let options = {
            message: msg,
            confirmButtonText,
          };
          try {
            await Dialog.alert(options);
            if (res.code === 0) {
              window.close();
              this.isClose = false;
            }
          } catch (err) {
            Toast.fail(err.message || "Dialog提交错误");
          }
        }
      } catch (err) {
        Toast.fail(err.message || "提交错误");
      } finally {
        this.loading = false;
      }
    },
    onFileChange() {
      this.taskStartTime = Date.now();
    },
    async handleSubmit() {
      if (this.loading) return;
      let imgs = this.$refs.poiImg.getImagesList();
      const submitInfo = await checkUploadImages(imgs);
      if (submitInfo) {
        this.loading = true;
        this.fetchTaskNew(submitInfo, imgs);
      }
    },
    handleCopy() {
      copyText(this.address);
    },
    clickFeedback() {
      this.$router.push({ path: '/abnormalFeedback'+ location.search});
    },
  },
};
</script>

<style lang="less" scoped>
.poiTaskDistribution {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 20px;
  font-size: 16px;
  .poiTaskDistributionMain {
    width: calc(100% - 30px);
    padding: 15px;
    margin-top: 25px;
    font-size: 14px;
    background-color: #e2e2e3;
    border-radius: 20px;
    .information {
      line-height: 30px;
      color: #6f6f71;
    }
    .copy {
      display: flex;
      align-items: center;
      span {
        display: inline-block;
        max-width: 80%;
      }
      .copyBtn {
        width: 20%;
        margin-left: 10px;
        color: #fff;
        background-color: #007aff;
      }
    }
    .poiTaskDistributionUploadFile {
      margin-top: 20px;
    }
  }
  .submit {
    display: flex;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    margin-top: 20%;
    .submitBtn {
      width: 100%;
      color: #fff;
      background-color: #007aff;
      border-radius: 16px;
    }
    .feedback {
      padding-top: 10px;
      font-size: 14px;
      text-align: center;
      .linkText{
        font-weight: bold;
        color: #3e7bf3
      }
    }
  }
}
</style>
